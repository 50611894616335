
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiBaseConfig, apiBaseConfigEdit } from "@/api/setting";
import MaterialSelect from "@/components/material-select/index.vue";

@Component({
  components: {
      MaterialSelect,
  },
})
export default class Config extends Vue {
  /** S Data **/

  // 添加商城表单数据
  form: any = {
    zhixiang_api: "", 
  };

  // 表单校验
  rules = {
    zhixiang_api: [
          { required: true, message: "请输入职享接口地址", trigger: "blur" },
      ],
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
      // 验证表单格式是否正确
      const refs = this.$refs[formName] as HTMLFormElement;
      refs.validate((valid: boolean): any => {
          if (!valid) return;

          this.handleBaseConfigEdit();
      });
  }

  // 编辑平台信息
  async handleBaseConfigEdit(): Promise<void> {
      await apiBaseConfigEdit({ ...this.form });
      this.getBaseConfigFunc();
  }

  // 获取详情
  async getBaseConfigFunc(): Promise<void> {
      const res = await apiBaseConfig();
      for (var key in res) {
          this.$set(this.form, key, res[key]);
      }
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
      this.getBaseConfigFunc();
  }
  /** E Life Cycle **/
}
